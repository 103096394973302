
#features {
  width: 100%;
  position: relative;
  z-index: 4;
  padding-top: 0;
  overflow: hidden;

  @include media('>=desktop') {
    padding-top: 18px;    
  }
}


#features.tall {
  height: 478px;
  background: transparent url(/img/bg_feature_tall.png) repeat-x;
}

#features.mid {
  height: 280px;
  background: url(/img/bg_feature_mid.png) repeat-x ;  
}

#features.short {
  height: 190px;
  background: url(/img/bg_feature_short.png) repeat-x;
  margin-bottom: -26px;
}

#features.mid.no-clients {
  height: 280px;
  margin-bottom: 0px;
}

#features .owl-nav {
  width: 100%;
  height: 1px;
  left: 50%;
  transform: translate(-50%);
  padding: 0;
  z-index: 90;
  top: 50%;
  max-width: 1100px;
  position: absolute;
  background-color: tansparent;
  overflow: visible;
}

#features .owl-nav [class*='owl-'] {
  display: block;
  width: 60px;
  height: 35px;
  position: absolute;
  top: 0px;
  transform: translate(0,-50%);
  z-index: 101;
  text-indent: -9999px;
}

#features .owl-nav .owl-prev {
  left: 0px;
  background: transparent url(/img/btn_prev_off.png) no-repeat scroll center center;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

#features .owl-nav .owl-prev:hover {
  background-image: url(/img/btn_prev_on.png);
  opacity: 1;
  filter: alpha(opacity=100);
}

#features .owl-nav .owl-next {
  right: 0px;
  background: transparent url(/img/btn_next_off.png) no-repeat scroll center center;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

#features .owl-nav .owl-next:hover {
  background-image: url(/img/btn_next_on.png);
  opacity: 1;
  filter: alpha(opacity=100);
}

#features .owl-item {
  z-index: 40;
}


#features .slide {
  width: 100%;
  position: relative;
  z-index: 50;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

#features.tall .slide {
  height: 477px;
}

.home #features.tall .slide {
  display: none;
}

#features.mid .slide {
  height: 280px;
}

#features.short .slide {
  height: 156px;
}

.home #features .slide:first-child {
  display: block;
}

.home #features #subaru {
  background: transparent url(/img/features/subaru.jpg) no-repeat scroll top center;
  background-size: cover;
  background-position: 52% 50%;
}
.home #features #volvo {
  background: transparent url(/img/features/volvo.jpg) no-repeat scroll top center;
  background-size: cover;
}
.home #features #panasonic {
  background: transparent url(/img/features/panasonic.jpg) no-repeat scroll top center;
  background-size: cover;
}

#features .slide .content {  
  margin: 0 auto;
  position: relative;
  display: block;
  z-index: 102;
  background-color: rgba(0,0,0,0.2);
}

#features.tall .slide .content {
  height: 477px;
  
}
#features.mid .slide .content {
  height: 280px;
}

#features.short .slide .content {
  height: 156px;
}


#features .slide .content>div {
  width: 75%;
  box-sizing: border-box;
  max-width: 250px;
  min-height: 87px;
  position: absolute;
  top: auto;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%,0);
  background: transparent url(/img/bg_feature_content.png) no-repeat scroll center bottom;
  background-color: #fff;
  z-index: 101;
  padding: 20px 15px 20px 15px;
}

#features .feature-heading {
    position: absolute;
      width: 90%;
      top: 20px;
      left: 20px;
      font-size: 20px;
      line-height: 24px;
      color: #fff;
      text-shadow: 3px 3px 6px rgba(0,0,0,1);
      margin: 0;
      padding: 0;
      display: block;
      font-style: italic;      

      em,strong {
        color: #e52420;
        font-size: 36px;
        line-height: 38px;
        display: block;        
      }
  }

  #features.short .feature-heading {
    top: 45%;
    transform: translate(0,-50%);
  }


.home {
  #features .slide .content h1 {
    position: absolute;
    width: 76%;
    top: 24px;
    left: 30px;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    text-shadow: 3px 3px 6px rgba(0,0,0,1);

    em,strong {
      color: #e52420;
      font-size: 42px;
      line-height: 56px;
      display: block;
      padding-left: 0;
    }

  }

  #features .slide .content>div h2 {
    font-size: 14px;
    line-height: 17px;
    font-style: italic;
    margin-bottom: 5px;
    color: #666;
    padding: 0;
  }

  #features .slide .content>div h3 {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 0;
    color: #e52420;
  }
  
}

#features .slide .content>div h1 {
  font-size: 14px;
  line-height: 17px;
  font-style: italic;
  margin-bottom: 0;
  color: #666;
  padding: 0;
}

#features .slide .content>div h2 {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 0;
  color: #e52420;
}

#features .slide .content>div ul {
  margin-top: 10px;
}

#features .slide .content>div li {
  text-transform: uppercase;
  font-size: 10px;
  line-height: 13px;
  list-style-image: url(/img/feature_content_li.png);
  margin-bottom: 0px;
}

#features div.link {
  float: right;
  background-image: url(/img/bg_feature_call_link_left.png);
}

#features div.link a, #features div.link a:visited {
  background-image: url(/img/bg_feature_call_link_right.png);
  color: #fff;
}


div.link:hover {
  background-image: url(/img/bg_link_left_over.png);
}

div.link:hover a {
  background-image: url(/img/bg_link_right_over.png);
  color: #fff;
}

#features div.link:hover {
  background-image: url(/img/bg_feature_call_link_left_over.png);
  color: #fff;
}

#features div.link:hover a {
  background-image: url(/img/bg_feature_call_link_right_over.png);
}

// ======== Tablet Styles =========== //

@include media(">=tablet") {

  #features .feature-heading {
    position: absolute;
      top: 30px;
      left: 70px;
      font-size: 18px;
      line-height: 50px;
      color: #fff;
      text-shadow: 3px 3px 6px rgba(0,0,0,1);
      margin: 0;
      padding: 0;
      display: block;
      font-style: italic;
      letter-spacing: 1px;

      em,strong {
        color: #e52420;
        font-size: 60px;
        display: block;
        line-height: 54px;        
      }
  }

}
// ======== Desktop Styles =========== //

@include media(">=desktop") {

  #features.tall {
    height: 478px;
    background: transparent url(/img/bg_feature_tall.png) repeat-x;
  }

  #features.mid {
    height: 306px;
    background: url(/img/bg_feature_mid.png) repeat-x;
    margin-bottom: -26px;
  }

  #features.short {
    height: 164px;
    background: url(/img/bg_feature_short.png) repeat-x;
    margin-bottom: -26px;
  }

  #features.mid.no-clients {
    height: 280px;
    margin-bottom: 0px;
  }

  #features .feature-heading {
    position: absolute;
      top: 50px;
      left: 70px;
      font-size: 18px;
      line-height: 42px;
      color: #fff;
      text-shadow: 3px 3px 12px rgba(0,0,0,1);
      margin: 0;
      padding: 0;
      display: block;
      font-style: italic;
      letter-spacing: 1px;

      em,strong {
        color: #e52420;
        font-size: 60px;
        display: block;
        padding-left: 0.5em;
      }
  }

  #features.short .feature-heading {
    top: 45%;
    transform: translate(0,-50%);
  }

  #features .slide {
    width: 100%;
    position: relative;
    z-index: 50;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #features.tall .slide {
    height: 477px;
  }

  .home #features.tall .slide {
    display: none;
  }

  #features.mid .slide {
    height: 280px;
  }

  #features.short .slide {
    height: 140px;
  }

  .home #features .slide:first-child {
    display: block;
  }

  #features .slide .content {
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    z-index: 102;
    background-color: transparent;
  }

  #features.tall .slide .content {
    height: 477px;
    
  }
  #features.mid .slide .content {
    height: 280px;
  }

  #features.short .slide .content {
    height: 140px;
  }

  #features .slide .content>div {
    width: 290px;
    max-width: 290px;
    min-height: 87px;
    position: absolute;
    top: 0;
    bottom: auto;
    right: 0;
    left: auto;
    transform: translate(3px,0);
    background: transparent url(/img/bg_feature_content.png) no-repeat scroll left bottom;
    z-index: 101;
    padding: 23px 30px 40px 45px;
  }

  .home #features #subaru {   
    background-size: cover;
    background-position: 50% 50%;
  }
  .home #features #volvo {    
    background-size: cover;
  }
  .home #features #panasonic {
    background-size: cover;
  }

  .home {
    #features .slide .content h1 {
      position: absolute;
      top: 20px;
      left: 30px;
      font-size: 28px;
      line-height: 76px;
      color: #fff;
      text-shadow: 3px 3px 10px rgba(0,0,0,1);

      em,strong {
        color: #e52420;
        font-size: 100px;
        display: block;
        padding-left: 0.5em;
      }
    }

    #features .slide .content>div h2 {
      font-size: 18px;
      line-height: 20px;
      font-style: italic;
      margin-bottom: 5px;
      color: #666;
    }

    #features .slide .content>div h3 {
      font-size: 18px;
      font-style: italic;
      margin-bottom: 0;
      color: #e52420;
    }
  }

  #features .slide .content>div h1 {
    font-size: 18px;
    line-height: 20px;
    font-style: italic;
    margin-bottom: 5px;
    color: #666;
  }

  #features .slide .content>div h2 {
    font-size: 18px;
    font-style: italic;
    margin-bottom: 0;
    color: #e52420;
  }

  #features .slide .content>div ul {
    margin-top: 10px;
  }

  #features .slide .content>div li {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 14px;
    list-style-image: url(/img/feature_content_li.png);
    margin-bottom: 0px;
  }

}
