@include media('<desktop') {

  #personnel {
    margin-left: -25px;
    margin-right: -25px;
    overflow: hidden;
  }

  #personnel-photos-wrapper {
    width: 100%;
    height: 116px;
    overflow: hidden;
  }

  #personnel-photos {
    width: 893px !important;
    position: relative;
    left: -285px;
    height: 116px;
  }

  #personnel .content {
    position: relative;
  }

  #personnel .content .person {
    position: absolute;
    width: 80%;
    padding: 15px 25px 0 25px;
    display: none;
  }

  #personnel .content .person:first-child {
    display: block;
  }

  #personnel .content .person h2 {
    font-size: 16px;
    line-height: 20px;
    color: #949494;
    padding-left: 15px;
    background: url(../img/bg_header_li.png) no-repeat scroll 0px 2px;    
  }

  #personnel .content .person h2 em {
    font-style: normal;
    color: #ffffff;
    margin-right: 5px;
    display: block;
  }

  #personnel .navigation {
    display: none !important;
  }  

}

@include media('>phone') {


  #personnel .content .person h2 em {
    display: inline;
  }
}

@include media('>=tablet') {

  #personnel-photos {    
    left: -114px;    
  }

  #personnel .content .person h2 em {
    display: inline;
  }
}

@include media('>=desktop') {
  #personnel {
    margin-left: -30px;
    margin-right: -30px;
  }

  #personnel-photos-wrapper {
    width: 100%;
    height: 116px;
    overflow: hidden;
  }

  #personnel-photos {
    width: 893px !important;
    position: relative;
    left: -330px;
    height: 116px;
  }

  #personnel .content {
    position: relative;
  }

  #personnel .content .person {
    position: absolute;
    width: 90%;
    padding: 15px 50px 0 30px;
    display: none;    
  }

  #personnel .content .person:first-child {
    display: block;
  }

  #personnel .content .person h2 {
    color: #ccc;
    padding-left: 15px;
    background: url(../img/bg_header_li.png) no-repeat scroll 0px 1px;
  }

  #personnel .content .person h2 em {
    font-style: normal;
    color: #ff0005;
    margin-right: 5px;
  }

  #personnel .navigation {
    display: none !important;
  }
}