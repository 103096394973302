$breakpoints: (
    phone: 320px, 
    tablet: 768px, 
    desktop: 1024px, 
    lg-desktop: 1200px
);

html,
button,
input,
select,
textarea {
    color: #222;
}

body {
    font-size: 1em;
    line-height: 1.4;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

body {
    font: 400 15px/22px 'Exo', sans-serif;
    background: #393939;
    color: #949494;
    padding-bottom: 40px;
}

a, a:visited {
    text-decoration: none;
    color: #fff;
}

h1 {
    text-transform: uppercase;
    color: #ff0005;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    margin: 0 0 10px;
}

h1.graytalic {
    font-style: italic;
    font-size: 18px;
    line-height: 24px;
    color: #cfcfcf;
    text-align: center;
}

h2 {
    text-transform: uppercase;
    color: #ff0005;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin: 0 0 10px;
}

h3 {
    text-transform: uppercase;
    color: #949494;
    font-size: 13px;
    font-weight: normal;
    line-height: 10px;
    margin: 0 0 4px;
    
}


@include media('>=tablet') {
    h1 {
        text-transform: uppercase;
        color: #ff0005;
        font-size: 22px;
        font-weight: normal;
        line-height: 28px;
        margin: 0 0 10px;
    }

    h1.graytalic {
        font-style: italic;
        font-size: 22px;
        line-height: 28px;
        color: #cfcfcf;
        text-align: center;
    }

    h2 {
        text-transform: uppercase;
        color: #ff0005;
        font-size: 18px;
        font-weight: normal;
        line-height: 18px;
        margin: 0 0 10px;
    }

    h3 {
        text-transform: uppercase;
        color: #949494;
        font-size: 15px;
        font-weight: normal;
        line-height: 10px;
        margin: 0 0 4px;
        
    }

    table#contacts {
        tbody {
            tr {
                td:first-child {
                    text-transform: uppercase;
                    text-align: right;
                }
            }
        }
    }    
}

table#contacts {
        tbody {
            tr {
                td:first-child {
                    text-transform: uppercase;                    
                }
            }
        }
    }    

p {
    margin: 0 0 14px;
}

ul {
    margin: 0 0 14px 18px;
    padding: 0;
    list-style-position: outside;
}

li {
    margin: 0 0 8px;
    list-style-image: url(/img/bg_header_li.png);
    list-style-position: outside;
    padding: 0 0 0 0px;
    line-height: 18px;
}

td {
    vertical-align: top;
    padding: 5px;
}



// Set up base classes for rows and cols
// to aid in making responsive
.row .row { margin:  0; }
.col      { padding: 0; }

.row:after {
    content: "";
    clear: both;
    display: table;
}

@media only screen { .col {
    float: left;
    width: 100%;
    box-sizing: border-box;
}}