
div.column h3 {
  margin-bottom: 7px;
}

div.column-right {
  margin-right: 0;
}


div.link {
  width: auto;
  float: left;
  height: 19px;
  line-height: 19px;
  background: url(/img/bg_link_left.png) no-repeat;
  padding-left: 13px;
  font-style: italic;
}

div.link a, div.link a:visited {
  display: block;
  background: url(/img/bg_link_right.png) no-repeat scroll top right;
  color: #ccc;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  padding: 4px 17px 6px 13px;
}

#clients {
  width: 100%;
  height: 104px;
  position: relative;
  z-index: 2;
  background: transparent url(/img/bg_clients_band_mobile.png) repeat-x scroll top center; 
}

#clients ul {  
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  height: 70px;  
}

#clients ul li {
  margin: 0;
  padding: 0;
  display: block;
  float: left;
  list-style-type: none;

  a {
    display: inline-block;
  }
}

#faces {
  width: 100%;
  height: 104px;
  position: relative;
  z-index: 2;
  background: transparent url(/img/bg_faces.png) repeat-x scroll top center;
}


#main {
  position: relative;    
  margin: -34px auto 0px auto;
  z-index: 3;
  padding-bottom: 40px;
}

#main.mid-no-clients, #main.short {
  margin-top: 0px;
}



#content {
  position: relative;
  z-index: 1;  
  width: 100%;
  min-height: 300px;    
  margin: 0;
  padding: 30px 25px;
}

.home #content {
  overflow: hidden;


  @include media('<tablet') {
    img.full-width-img {
      margin: 0 -25px 20px;
      clear: both;
      width: 130%;
      max-width: 120%;
    }
  }

}

.report {
  margin-bottom: 18px;
}

.report:after {
    content: " ";
    display: table;
}

.report:after {
    clear: both;
}

.report {
    *zoom: 1;
}

.report h3 {  
  float: none;
  clear: both;
  margin-bottom: 10px;
  font-size: 14px;
  color: #ff0005; 
}

.report img {
  margin: 20px 0 10px -10px;
}

.report p {  
  float: right;
  margin: 0;
}

.sidebar {  
  position: relative;
  float: none;
  clear: both;
  box-shadow: inset 0 10px 20px -7px rgba(0,0,0,0.45);
}

.sidebar > div {
  padding: 35px 25px;

  h2 {
    font-size: 18px;
    line-height: 22px;
  }
}

.sidebar.left > div.red-hash {
  background: url(/img/gradient_red_hash.png) no-repeat scroll 25px 0px;
  padding: 35px 25px 35px 55px;
}

.sidebar.right > div.red-hash {
    background: url(/img/hash.png) no-repeat scroll 0px 0px;    
}

.sidebar > div.gray-hash {
  background: url(/img/gradient_gray_hash.png) no-repeat scroll 25px 0px;
  padding: 35px 25px 35px 50px;
}

.sidebar.right > div {  
  min-height: 200px;
}

.sidebar .twitter {
  position: relative;
  z-index: 10000;
}

.sidebar .twitter > h2,
.sidebar .twitter > h2 a {
  font-size: 15px !important;
  color: #6e6e6e;  
}

.twitter h2 a, .twitter h2 a:visited {
  color: #6e6e6e;
}

.twitter a, .twitter a:visited {
  color: #ff0005;
}

.brands h2 {
  margin-bottom: 0;
}

.quote .content {  
  margin-top: -11px;
  background: url(/img/quote_left.png) no-repeat;
  padding: 11px 0 0 28px;
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
}

.right .quote .content {
  margin-left: -15px;
}

.quote .content img {
  vertical-align: top;
}

.quote .attribution {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 15px;
  font-style: italic;
  text-align: right;
  margin-top: 5px;
}

.quote .attribution .company {
  color: #e52420;
}
.stat {
  font-size: 20px;
  font-style: italic;
  color: #cfcfcf;
}
.stat-title {
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

@include media('>=tablet') {
  div.column {
    box-sizing: border-box;
    width: 50%;
    float: left;
    padding-right: 10px;
  }

  div.column h3 {
    margin-bottom: 7px;
  }

  div.column-right {
    margin-right: 0;
  }

}


// ======== Desktop Styles =========== //

@include media('>=desktop') {
  

  div.map {
    margin-left: -25px;
  }


  div.link {
    width: auto;
    float: left;
    height: 19px;
    line-height: 19px;
    background: url(/img/bg_link_left.png) no-repeat;
    padding-left: 13px;
    font-style: italic;
  }

  div.link a, div.link a:visited {
    display: block;
    background: url(/img/bg_link_right.png) no-repeat scroll top right;
    color: #ccc;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1;
    padding: 4px 17px 6px 13px;
  }

  .sidebar.right>div {
    padding-left: 25px;
    min-height: 300px;
  }


  #clients {
    width: 100%;
    min-width: 980px;
    height: 104px;
    position: relative;
    z-index: 2;
    background: transparent url(/img/bg_clients_band.png) repeat-x scroll top center;
    overflow-x: auto;
    overflow-y: hidden;
  }

  #clients ul {
    max-width: 980px;
    margin: 0 auto;
    padding: 0;
  }

  #clients ul li {
    margin: 0;
    padding: 0;
    display: block;
    float: left;
    list-style-type: none;
  }

  #faces {
    width: 100%;
    min-width: 980px;
    height: 104px;
    position: relative;
    z-index: 2;
    background: transparent url(/img/bg_faces.png) repeat-x scroll top center;
  }

  #main {
    position: relative;  
    max-width: 1100px;
    margin: -34px auto 0px auto;
    z-index: 3;
    padding: 0 15px 40px;
  }

  #main.mid-no-clients, #main.short {
    margin-top: 0px;
  }

  #content {
    position: relative;
    background: transparent url(/img/bg_content-wide.png) no-repeat scroll bottom left;
    background-size: 100% auto;
    z-index: 1;
    // width: 592px;
    width: 53.8181818%;
    min-height: 300px;
    float: left;
    // margin: 0 0 0 254px;
    margin: 0 0 0 23.0909091%;
    padding: 36px 51px;
  }

  .report {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .report:after {
      content: " ";
      display: table;
  }

  .report:after {
      clear: both;
  }

  .report {
      *zoom: 1;
  }

  .report img {
    float: left;
    margin: -12px 15px 0 -10px;
    display: inline;
  }

  .report h3 {
    width: 59%;
    float: left;
    clear: none;
    margin-bottom: 10px;
    font-size: 14px;
    color: #ff0005; 
  }

  .report p {
    width: 59%;
    float: left;
    margin: 0;
  }

  .sidebar {
    width: 25%;
    position: relative;
    z-index: 0;
    float: left;
    clear: none;
    box-shadow: none;
  }

  .sidebar>div {
    padding: 30px 25px 17px 0;

  }

  .sidebar .full-width-img {
    margin: 0 -25px 0 -15px;

    img {
      width: 100%;
      margin: 0;
      padding: 0;
      max-width: none;
    }
  }

  .sidebar.left {
    width: 25%;
    margin: 0 0 0 -76.9090909%;
  }

  .sidebar.right {
    margin: 0;
    // width: 275px;
    width: 25%;
    margin-left: -21px;
  }

  .sidebar.right>div {
    padding-left: 25px;
    min-height: 300px;
  }

  .sidebar.left > div.red-hash {
    background: url(/img/gradient_red_hash.png) no-repeat scroll 4px 0px;
    background-size: 38px 50px;
    padding: 35px 25px 35px 0px;

    h2 {
      padding-left: 40px;
    }
  }
  
  .sidebar.right > div.red-hash,
  .sidebar.right > div.twitter {
    background: url(/img/hash.png) no-repeat scroll 2px 0px;    
  }

  .sidebar > div.gray-hash {
    background: url(/img/hash_gray.png) no-repeat scroll 2px 0px;
    padding: 35px 25px;
  }

  .brands h2 {
    margin-bottom: 0;
  }


  .quote .content {
    margin-left: -5px;
    margin-top: -11px;
    background: url(/img/quote_left.png) no-repeat;
    padding: 11px 0 0 28px;
    font-size: 14px;
    line-height: 20px;
    font-style: italic;
  }

  .right .quote .content {
    margin-left: -15px;
  }

  .quote .content img {
    vertical-align: top;
  }

  .quote .attribution {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 15px;
    font-style: italic;
    text-align: right;
    margin-top: 5px;
  }

  .quote .attribution .company {
    color: #e52420;
  }
  .stat {
    font-size: 20px;
    font-style: italic;
    color: #cfcfcf;
  }
  .stat-title {
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

}