.rtable {
    max-width: 100%;
}

.rtable > thead > tr > th,
.rtable > tbody > tr > td {
    white-space: nowrap;
}

.rtable.active,
.rtable.active thead,
.rtable.active tbody,
.rtable.active th,
.rtable.active td,
.rtable.active tr {
    display: block;
}


/* NOTABLE LAYOUT */
.rtable.notable.active > thead {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
}

.rtable.notable.active > tbody > tr {
    padding-top: 5px;
    padding-bottom: 5px;
}
.rtable.notable.active > tbody > tr + tr {
    border-top: 1px solid;
}

.rtable.notable.active > tbody > tr > td {
    position: relative;
    padding-left: 50% !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    white-space: normal;
}

.rtable.notable.active > tbody > tr > td:before {
    display: block;
    float: left;
    width: 95%;
    min-height: 1px;
    margin-left: -100%;
    left: 0;
    font-weight: bold;
    content: attr(data-title);
    text-align: right;
}
.rtable.notable.active > tbody > tr > td:after {
    content: ' ';
    display: table;
    clear: both;
}



/* FLIPSCROLL LAYOUT */
.rtable.flipscroll.active {
    position: relative;
    width: 100%;
}
.rtable.flipscroll.active > thead {
    float: left;
    border-right: 1px solid;
}
.rtable.flipscroll.active > thead > tr {
    padding-right: 5px;
}
.rtable.flipscroll.active > tbody {
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
}
.rtable.flipscroll.active > tbody > tr {
    display: inline-block;
    padding: 0 5px;
}
.rtable.flipscroll.active > tbody > tr + tr {
    border-left: 1px solid;
}
