header {  
  margin: 0 auto;
  font-style: italic;
  font-size: 15px;
  text-transform: uppercase;
  position: relative;  
}

header #print-logo {
  display: none;
}

header img.branding {
  display: inline-block;
  width: 225px;
  height: auto;
}

header>nav>ul {
  margin: 20px 0 0 0;
  padding: 0;
  float: left;
}


header>nav>ul>li {
  list-style-type: none;
  list-style-image: none;
  display: block;
  margin: 0;
}

header ul li a, header ul li a:visited {
  display: inline-block;  
  color: #949494;
}

header ul li.active>a, header ul li.active>a:visited, header ul li.active>a:hover {
  color: #ff0005;
}  

header ul li a:hover, header ul li ul li a:hover  {
  color: #fff !important;
}

header ul li ul {
 margin: 0;
 padding: 0;
}


header ul li ul li {
  list-style-type: none;
  display: block;
  margin: 0; 
  white-space: nowrap;
  clear: both;
}


// Desktop Header Styles

@include media(">=desktop") {
  #menu-toggle {
    display: none ;
  }

  header {
    max-width: 1100px;
    margin: 0 auto;
    font-style: italic;
    font-size: 18px;
    text-transform: uppercase;
    padding: 0 15px;

    @include media(">lg-desktop") {
      padding: 0;
    }
  }

  header #print-logo {
    display: none;
  }

  header > #screen-logo, header > #screen-logo:visited, header > #screen-logo:active {
    display: block;
    float: left;
    margin: 22px 25px -2px 0;
  }

  header>nav>ul {
    max-width: 860px;
    margin: 28px 0 0 0;
    padding: 0;
    float: right;
  }

  header>nav>ul>li {
    list-style-type: none;
    list-style-image: none;
    display: block;
    float: left;
    position: relative;
    margin: 0;
    padding: 0 12px 0 25px;
    font-size: 17px;
    font-weight: 300;

    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 3px;
      height: 18px;
      top: 50%;
      left: 0px;
      border-right: 2px solid #d42622;
      border-left: 2px solid #d42622;
      transform: skew(-24deg) translate(0, -50%);
    }    
  }

  @include media(">lg-desktop") {

    header>nav>ul>li {
      padding: 0 15px 0 28px;
      font-size: 18px;
      font-weight: 300;
    }

    &:before {
      height: 20px;      
    }    

  }


  header>nav>ul>li:first-child {
    background: 0;
    
    &:before {
      display: none;
    }      
  }

  header>nav>ul>li:last-child {
    padding-right: 0;
  }

  header ul li.go-back {
    display: none;
  }

  header ul li a, header ul li a:visited {
    display: block;
    width: 100%;
    color: #949494;
  }

  header ul li .subnav-trigger {
    display: none;
  }

  header ul li.active>a, header ul li.active>a:visited, header ul li.active>a:hover {
    color: #ff0005;
  }  

  header ul li a:hover, header ul li ul li a:hover  {
    color: #fff !important;
  }

  header ul li ul {
    display: none;
    position: absolute;
    z-index: 10;
    top: 17px;
    left: 0;
    padding: 10px 20px 10px 20px;
    margin: 0;
    background: #383838;
    box-shadow: 0px 5px 10px #000;
    -webkit-box-shadow: 0px 5px 10px #000;
    -moz-box-shadow:  0px 5px 10px #000;
    // width: 250px;
  }


  header>nav>ul>li:hover ul {
    display: block;
  }

  header ul li ul li {
    list-style-type: none;
    display: block;
    margin: 8px 0;
    padding: 0 0 0 20px;    
    white-space: nowrap;
    clear: both;
    position: relative;
    font-size: 16px;

     &:before {
      position: absolute;
      content: "";
      display: block;
      width: 3px;
      height: 12px;
      top: 50%;
      left: 0px;
      border-right: 2px solid #d42622;
      border-left: 2px solid #d42622;
      transform: skew(-24deg) translate(0, -50%);
    }
  }
  header ul.main-menu li.edge > ul {
    right: 0;
    left: auto;
  }


}

// Mobile only styles
@include media("<desktop") {

  header {    
    padding: 20px 0 12px;
    z-index: 100;
    box-shadow: inset 0 -15px 15px -10px rgba(0,0,0,0.4);
    position: relative;

    #menu-toggle {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    >#screen-logo, 
    >#screen-logo:visited, 
    >#screen-logo:active {
      display: inline-block;    
      padding: 0;
      width: auto;  
      text-align: center;
      z-index: 100;
      position: relative;
      margin-left: 50%;
      transform: translate(-50%,0);      

      img {
        display: inline-block;

      }
    }


    // Mobile navigation

    nav.main-navigation {
      position: absolute;
      top: 0;
      right: 0px;
      width: 100%;
      margin-top: 56px;
      background-color: #393939;
      height: 0px;
      overflow: hidden;
      transform: translateZ(0);
      backface-visibility: hidden;
      transition: all 0.3s;
      box-shadow: -5px 10px 10px rgba(0,0,0,0.25);

    }

    .main-menu {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 50%;
      width: 70%;
      height: 100%;      
      overflow-x: hidden;
      overflow-y: auto;
      transition: visibility 0s 0.7s;
      max-width: 280px;
      transform: translate(-50%,0);
    }

    .main-menu li {
      padding: 0px 10% 0px 12.5%;
    }

    .main-menu a {
      display: inline-block;      
      font-size: 16px;
      padding: 10px 0 10px 0%;
      transition: transform 0.3s, opacity 0.3s;
    }

    .main-menu li ul {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: translateX(100%);
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;

      li {
        a {
          white-space: normal;
        }
      }

    }

    .main-menu li.menu-shown ul {
      visibility: visible;
      opacity: 1;
    }

    .main-menu.nav-is-visible {
      visibility: visible;
      transition: visibility 0s 0s;
    }

    .main-menu.moves-out > li > a {
      /* push the navigation items to the left - and lower down opacity - when secondary nav slides in */     
      transform: translateX(-100%);
      opacity: 0;
    }

    .main-menu.moves-out > li > ul {      
      transform: translateX(0);
    }

    .main-menu .subnav-trigger, 
    .main-menu .go-back a {
      position: relative;
    }

    .main-menu .go-back a {
      color: #ff0005;      

      &:before {
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: -18px;
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        border-left: 2px solid #ff0005;
        border-bottom: 2px solid #ff0005;
        transform: rotate(45deg);
      }   
    }

    .main-menu li .subnav-trigger {
      display: inline-block;
      position: relative;
      width: 15px;      
      font-size: 1em;
      line-height: 1em;
      text-align: center;
      padding: 10px;
      text-indent: -9999px;
      margin-left: 5px;
      float: right;

      &:before {
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 8px;
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        border-top: 2px solid #ff0005;
        border-right: 2px solid #ff0005;
        transform: rotate(45deg);
      }     
    }

    .main-menu li.active .subnav-trigger:before {
      border-color: #ff0005;      
    }

    &.nav-is-visible{
      box-shadow: none;

      nav.main-navigation {
        height: 100vh;        
      }

    }
  }

}

// Hamburger/Menu toggle styles & animations
.hamburger {
  padding: 10px 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  outline: none;

  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 30px;
  height: 20px;
  display: block;  
  position: relative; }

.hamburger-inner {
  text-indent: -9999px;
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 4px;
    background-color: #616161;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -8px; }
  .hamburger-inner::after {
    bottom: -8px; }

.hamburger--slider .hamburger-inner {
  top: 2px; 
}
.hamburger--slider .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s; 
}
.hamburger--slider .hamburger-inner::after {
  top: 16px; 
}

.hamburger--slider.is-active .hamburger-inner {
  background-color: #e52222;
  transform: translate3d(0, 10px, 0) rotate(45deg); 
}
.hamburger--slider.is-active .hamburger-inner::before {
  background-color: #e52222;
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0; 
}
.hamburger--slider.is-active .hamburger-inner::after {
  background-color: #e52222;
  transform: translate3d(0, -16px, 0) rotate(-90deg); 
}

