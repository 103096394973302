@include media('<=desktop') {
  footer {
    margin: 0;
    font-style: italic;
    font-size: 14px;
    text-transform: uppercase;
    clear: both;
    text-align: center;
    padding-top: 40px;
    box-shadow: inset 0 10px 20px -7px rgba(0,0,0,0.45);

    .footer-inner {

      > div {
        box-sizing: border-box;  
        display: inline-block;
        padding: 7px 25px;      
        color: #797979;
        line-height: 27px;
        position: relative;      

        &:after {
          position: absolute;
          content: "";
          display: block;
          width: 3px;
          height: 18px;
          top: 50%;
          right: 4px;
          border-right: 2px solid #d42622;
          border-left: 2px solid #d42622;
          transform: skew(-18deg) translate(0, -50%);
        }
      }

      > .copyright,
      > .find-us,
      > .share {
        display: inline-block;
        width: auto;
        margin: 0 auto;
        clear: both;      
      }

      div.find-us {
        .fa {
          font-size: 32px;
          color: #797979;
          vertical-align: middle;
          margin-left: 5px;
        }
      }
      
      .share {
        display: inline-block;
        width: 95%;
        padding: 14px 15px 0px 0px;
        > span {
          float: left;
        }
        .addthis_toolbox {
          display: inline-block;
          float: left;
          margin-right: 0px;
          margin: -2px 0 0 10px;
          min-width: 200px;

          > a span {
            width: 30px !important;
            height: 30px !important;

            svg {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }
      }

      > div:last-child {
        &:after {
          display: none;
        }
      }


    }

    
  }


}

@media (min-width: 425px) and (max-width: 1023px) {

  footer {
    width: auto;

    .footer-inner {
      width: 90%;
      margin: 0 auto;
      text-align: center;      

      > div {
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;        
      }

      > div.copyright,
      > div.find-us,
       {      
        clear: none;
        text-align: center !important;
      }

      div.find-us,
      div.login {
        clear: right;
      }
      div.share {
        width: auto;
      }
    }    
  }
}


@include media('>=desktop') {

  footer {
    width: 96%;
    max-width: 1100px;
    margin: 0 auto;
    font-style: italic;
    font-size: 13px;    
    text-transform: uppercase;
    clear: both;
    vertical-align: middle;
    padding: 0 15px;
    display: block;    
    text-align: center;
  }

  footer * {
    vertical-align: middle;
  }

  footer .footer-inner > div {
    display: inline-block;
    padding: 0 5px 0 25px;
    color: #797979;
    position: relative;
    line-height: 32px;
    margin-right: 5px;

    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 3px;
      height: 16px;
      top: 48%;
      left: 0px;
      border-right: 2px solid #d42622;
      border-left: 2px solid #d42622;
      transform: skew(-24deg) translate(0, -50%);
    } 

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:last-child {
      padding: 0 0 0 25px;
    }
  }

  footer .footer-inner > div a, footer .footer-inner > div a:visited {
    color: #cfcfcf;
  }

  footer .footer-inner > div.copyright {
    padding-left: 0;
    background: none;
  }

  div.find-us {
        .fa {
          font-size: 28px;
          color: #797979;
          vertical-align: middle;
          margin-left: 5px;
        }
      }

  footer div.share {    
    min-width: 25%;
    padding-top: 1px;
  }

  footer div.share span {
    float: left;
    margin-right: 5px;
  }

  footer div.share .addthis_toolbox {
    float: left;
    display: inline-block;
    margin-right: 0px;    
    min-width: 180px;    

    > a span {
          width: 24px !important;
          height: 24px !important;

          svg {
            width: 100% !important;
            height: 100% !important;
          }
        }
  }

}

@include media(">=lg-desktop") {
  footer {
    width: 95%;
    font-size: 14px;

    .footer-inner {

          div.find-us {
      .fa {
        font-size: 32px;
        color: #797979;
        vertical-align: middle;
        margin-left: 5px;
      }
    }

    div.share .addthis_toolbox {
      float: left;
      display: inline-block;
      margin-right: 0px;

      > a span {
            width: 28px !important;
            height: 28px !important;

            svg {
              width: 100% !important;
              height: 100% !important;
            }
          }
    }

    }

  }
}