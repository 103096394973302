/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow:none !important;
        text-shadow: none !important;
    }

  header {
    height: 1cm;
    background: url(/img/logo-print.jpg) no-repeat !important;

    .hamburger {
        display: none;
    }
  }

  #features {
    height: auto;

    &.mid,
    &.short {
        height: auto;
    } 

    .slide,
    .content {
        height: auto;
    }

    h1.feature-heading {
        position: relative;
        font-size: 32px;
        line-height: 36px;
        margin: 0;
        padding: 0;

        em {
            font-size: inherit;
            padding: 0;
        }
    }
  }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links for images, or javascript/internal links
     */

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

  header ul, header a, footer, #clients, #faces, .carousel nav {
    display: none;
  }
  
  
  


}