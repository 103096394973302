.carousel {
  position: relative;
}

.carousel.brands .slide {  
  padding: 0 5px;
}

.carousel.brands .slide p.logo {
  text-align: center;
  margin: 0;

  img {
    width: auto;
    display: inline-block;
  }
}

.carousel.brands .slide h3 {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  font-style: italic;
  text-align: center;
  margin-bottom: 10px;
}

.carousel.brands .slide ul li {
  font-size: 12px;
  line-height: 14px;
}

.twitter .carousel .slide .ago {
  font-size: 10px;
  text-transform: uppercase;
}

.carousel.owl-carousel {
  padding: 0 0 30px;

  .owl-nav .owl-prev,
  .owl-nav .owl-next {
    display: block;
    width: 37px;
    height: 18px;
    text-align: center;
    position: absolute;
    bottom: auto;
    top: 50%;
    transform: translate(0,-50%);
    z-index: 100;
    font-size: 28px;
    line-height: 20px;
    text-indent: -9999px;
  }

  .owl-nav .owl-prev {
    left: 0px;
    background: transparent url(/img/btn_prev_sm_off.png) no-repeat scroll 0 0;
  }

  .owl-nav .owl-prev:hover {
    background-image: url(/img/btn_prev_sm_on.png);
  }

  .owl-nav .owl-next {
    right: 0px;
    background: transparent url(/img/btn_next_sm_off.png) no-repeat scroll 0 0;
  }

  .owl-nav .owl-next:hover {
    background-image: url(/img/btn_next_sm_on.png);
  }

}

.carousel.brands {
  .owl-nav .owl-prev,
  .owl-nav .owl-next {
    top: 12%;
  }
}

.twitter .carousel {
  .owl-nav .owl-prev,
  .owl-nav .owl-next {
    top: auto;
    bottom: 0px;
  }
}


